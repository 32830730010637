// extracted by mini-css-extract-plugin
export var content_root = "Header-module--content_root--dKPl2";
export var nav_right_btn_root = "Header-module--nav_right_btn_root--2Hjdv";
export var nav_right_hamburger_content = "Header-module--nav_right_hamburger_content--6Kvs7";
export var nav_right_hamburger_root = "Header-module--nav_right_hamburger_root--3teaH";
export var nav_right_link = "Header-module--nav_right_link--coGaY";
export var nav_right_menu_humbergur_click = "Header-module--nav_right_menu_humbergur_click--3r8N4";
export var nav_right_menu_root = "Header-module--nav_right_menu_root--2VzZH";
export var root = "Header-module--root--3fdPo";
export var title_link = "Header-module--title_link--24xR7";
export var title_root = "Header-module--title_root--3sd9M";