import React from 'react'
import { Link } from 'gatsby'
import ClassNames from 'classnames'

import { PostPageContext } from '@gatsby-node/createPostPages.ts'
import { Image } from '@/components/atoms'
import { UpdatedOn, CreatedOn, Tags } from '@/components/molecules'

import * as styles from './PostCard.module.scss'

type Props = {
  className?: string
  excerpt?: boolean
  pageContext: PostPageContext
}

const PostCard: React.FC<Props> = ({ className, excerpt, pageContext }) => {
  const { node } = pageContext
  const f = node.frontmatter
  const slug = node.fields.slug || node.frontmatter.slug

  const rootClassName = ClassNames({
    [styles.root]: true,
    [className!]: className
  })

  return (
    <article className={rootClassName}>
      <Link className={styles.link} key={`${slug}-link`} to={slug}>
        <div className={styles.thumbnail}>
          <Image image={f.image} />
        </div>
        <div className={styles.content_root}>
          <h2 className={styles.content_title}>{f.title}</h2>
          <div className={styles.supplement_root}>
            {(f.createdOn < f.updatedOn) && <UpdatedOn date={f.updatedOn} containerStyle={{ justifyContent: 'flex-start' }} />}
            {(f.createdOn >= f.updatedOn) && <CreatedOn date={f.createdOn} containerStyle={{ justifyContent: 'flex-start' }} />}
            {excerpt && <Tags className={styles.tags} tags={f.tags} limit={2} />}
          </div>
          {excerpt && <p className={styles.content_excerpt}>{node.excerpt}</p>}
        </div>
      </Link>
    </article>
  )
}

export default PostCard
