import React, { useEffect } from 'react'
import { createGlobalState } from 'react-hooks-global-state'
import { Link } from 'gatsby'
import ClassNames from 'classnames'

import { PaginateArgs } from '@gatsby-node/createPages/createIndexPage'
import { colors } from '@styles/variables'

import * as styles from './Pagination.module.scss'

const OMIT = '...'
const initialState = { pageData: [] }
const { useGlobalState } = createGlobalState(initialState)

interface CustomPaginateArgs extends PaginateArgs {
  className?: string
  pathname: string
}

const Pagination: React.FC<CustomPaginateArgs> = ({ className, context }) => {
  const {
    previousPagePath,
    nextPagePath,
    numberOfPages,
    pageNumber: currentPageIndex,
    humanPageNumber,
    pathname,
  } = context

  const [pageData, setPageData] = useGlobalState('pageData')

  // behave like componentDidMount
  useEffect(() => {
    setPageData([])
    setPageProperties()
  }, [])

  const setPageProperties = () => {
    let left, center, right: Array<number | string> = []

    // |<1>|2|3|4|5|6|7|8|
    // |1|2|3|4|5|6|7|<8>|
    if (numberOfPages < 9) {
      left = makeSerialData(numberOfPages)
      setPageData(left)
    } else if (numberOfPages < 11) {

      // |<1>|2|3|4|5|...|9|10|
      // |1|2|<3>|4|5|...|9|10|
      // |1|2|3|<4>|5|6|...|9|10|
      if (humanPageNumber < 5) {
        const size = (humanPageNumber < 4) ? 5 : humanPageNumber + 2
        left = makeSerialData(size)
        right = makeSerialData(2).map((_, i) => numberOfPages - i).reverse()
        setPageData([...left, OMIT, ...right])

        // |1|2|3|4|<5>|6|7|8|9|10|11|
        // |1|2|3|4|5|<6>|7|8|9|10|11|
      } else {
        left = makeSerialData(numberOfPages)
        setPageData(left)
      }
    } else if (numberOfPages === 11) {

      // |<1>|2|3|4|5|...|10|11|
      // |1|2|<3>|4|5|...|10|11|
      // |1|2|3|<4>|5|6|...|10|11|
      if (humanPageNumber < 5) {
        const size = (humanPageNumber < 4) ? 5 : humanPageNumber + 2
        left = makeSerialData(size)
        right = makeSerialData(2).map((_, i) => numberOfPages - i).reverse()
        setPageData([...left, OMIT, ...right])

        // |1|2|3|4|<5>|6|7|8|9|10|11|
        // |1|2|3|4|5|<6>|7|8|9|10|11|
        // |1|2|3|4|5|6|<7>|8|9|10|11|
      } else if ((5 <= humanPageNumber) && (humanPageNumber <= 7)) {
        left = makeSerialData(numberOfPages)
        setPageData(left)

        // |1|2|...|6|7|<8>|9|10|11|
      } else {
        left = makeSerialData(2)
        right = makeSerialData(numberOfPages).slice(humanPageNumber - 3)
        setPageData([...left, OMIT, ...right])
      }
    } else if (numberOfPages < 13) {

      // |<1>|2|3|4|5|...|11|12|
      // |1|2|<3>|4|5|...|11|12|
      // |1|2|3|<4>|5|6|...|11|12|
      if (humanPageNumber < 5) {
        const size = (humanPageNumber < 4) ? 5 : humanPageNumber + 2
        left = makeSerialData(size)
        right = makeSerialData(2).map((_, i) => numberOfPages - i).reverse()
        setPageData([...left, OMIT, ...right])

        // |1|2|3|4|<5>|6|7|...|11|12|
        // |1|2|3|4|5|<6>|7|8|...|11|12|
      } else if ([5, 6].includes(humanPageNumber)) {
        left = makeSerialData(humanPageNumber + 2)
        right = makeSerialData(2).map((_, i) => numberOfPages - i).reverse()
        setPageData([...left, OMIT, ...right])

        // |1|2|3|4|5|6|<7>|8|9|10|11|12|
        // |1|2|3|4|5|6|7|<8>|9|10|11|12|
      } else if ([7, 8].includes(humanPageNumber)) {
        left = makeSerialData(numberOfPages)
        setPageData(left)

        // |1|2|...|7|8|<9>|10|11|12|
      } else {
        left = makeSerialData(2)
        right = makeSerialData(numberOfPages).slice(humanPageNumber - 3)
        setPageData([...left, OMIT, ...right])
      }
    } else {
      // |<1>|2|3|4|5|...|12|13|
      // |1|2|<3>|4|5|...|12|13|
      // |1|2|3|<4>|5|6|...|12|13|
      if (humanPageNumber < 5) {
        const size = (humanPageNumber < 4) ? 5 : humanPageNumber + 2
        left = makeSerialData(size)
        right = makeSerialData(2).map((_, i) => numberOfPages - i).reverse()
        setPageData([...left, OMIT, ...right])

        // |1|2|3|4|<5>|6|7|...|12|13|
        // |1|2|3|4|5|<6>|7|8|...|12|13|
      } else if ([5, 6].includes(humanPageNumber)) {
        left = makeSerialData(humanPageNumber + 2)
        right = makeSerialData(2).map((_, i) => numberOfPages - i).reverse()
        setPageData([...left, OMIT, ...right])

        // |1|2|...|5|6|<7>|8|9|...|12|13|
      } else if (((humanPageNumber >= 7) && (humanPageNumber < numberOfPages / 2)) || ((numberOfPages - humanPageNumber) >= 6)) {
        left = makeSerialData(2)
        center = makeSerialData(humanPageNumber + 2).slice(humanPageNumber - 3)
        right = makeSerialData(2).map((_, i) => numberOfPages - i).reverse()
        setPageData([...left, OMIT, ...center, OMIT, ...right])

        // |1|2|...|5|6|<7>|8|9|10|11|12|13|
      } else if ((numberOfPages - humanPageNumber) < 6) {
        left = makeSerialData(2)
        const offset = ((numberOfPages - humanPageNumber) <= 2) ? numberOfPages - 6 : humanPageNumber - 3
        right = makeSerialData(numberOfPages).slice(offset)
        setPageData([...left, OMIT, ...right])
      }
    }
  }

  const makeSerialData = (size: number): Array<number> => {
    return [...Array(size)].map((_, i) => ++i)
  }

  const movePagePath = (pageNum: number): string => {
    if (pageNum === 1) {
      return pathname.replace(/\/page\/\d+/, '/')
    } else {
      if (/page/.test(pathname)) {
        return pathname.replace(/\/page\/\d+/, `/page/${pageNum}`)
      } else {
        return `${pathname}/page/${pageNum}`
      }
    }
  }

  const disabled = {
    color: '#d1d5da',
    cursor: 'default',
    backgroundColor: '#fafbfc',
    pointerEvents: 'none',
  }

  const prevStyles = () => {
    return (currentPageIndex > 0)
      ? {}
      : disabled
  }

  const nextStyles = () => {
    return (numberOfPages === currentPageIndex + 1)
      ? disabled
      : {}
  }

  const pageStyles = (pageNum: number) => {
    return (pageNum === currentPageIndex + 1)
      ? { color: 'white', background: colors.brand }
      : {}
  }

  const rootClassName = ClassNames({
    [styles.root]: true,
    [className!]: className
  })

  if (pageData.length === 2) {
    return (
      <div className={rootClassName}>
        <div className={styles.content_root}>
          <Link className={styles.content_link} to={previousPagePath} key='pagination_previous' style={prevStyles()}>Previous</Link>
          <Link className={styles.content_link} to={nextPagePath} key='pagination_next' style={nextStyles()}>Next</Link>
        </div>
      </div>
    )
  } else if (pageData.length > 1) {
    return (
      <div className={rootClassName}>
        <div className={styles.content_root}>
          <Link className={styles.content_link} to={previousPagePath} key='pagination_previous' style={prevStyles()}>Previous</Link>
          {pageData.map((n: number | string) => {
            if (n === OMIT) {
              return <Link className={styles.content_link} key={`pagination_${n}`} style={disabled}>{n}</Link>
            } else {
              return <Link className={styles.content_link} to={movePagePath(n)} key={`pagination_${n}`} style={pageStyles(n)}>{n}</Link>
            }
          })}
          <Link className={styles.content_link} to={nextPagePath} key='pagination_next' style={nextStyles()}>Next</Link>
        </div>
      </div>
    )
  } else {
    return <span />
  }
}
export default Pagination
