import React from 'react'
import { Link } from 'gatsby'

import { pathOptimize } from '@/utils'
import * as styles from './Tags.module.scss'

type Props = {
  className?: string
  tags?: string[]
  limit?: number
}

const Tags: React.FC<Props> = ({ className, tags, limit }) => {
  return (
    <div className={className}>
      <div className={styles.root}>
        {tags &&
          (limit ? tags.slice(0, limit) : tags).map((tag: string) => {
            return (
              <Link className={styles.link} key={tag} to={pathOptimize(`/tags/${tag}/`)}>
                #{tag}
              </Link>
            )
          })}
      </div>
    </div>
  )
}

export default Tags
