import React from 'react'
import ClassNames from 'classnames'
import { MarkdownRemarkEdge } from "@types/graphql-types"
import { UpdatedOn, CreatedOn, Tags } from '@/components/molecules'
import { Image, Breadcrumb } from '@/components/atoms'

import * as styles from './BlogPost.module.scss'
import './CustomBlock.scss'

type Props = MarkdownRemarkEdge & {
  className?: string
  breadcrumbs: string[]
}

const BlogPost: React.FC<Props> = ({ className, node, breadcrumbs }) => {
  const f = node.frontmatter
  const tags = f.tags
  const rootClassName = ClassNames({
    [styles.root]: true,
    [className!]: className
  })

  return (
    <div className={rootClassName}>
      <article key={node.id}>
        <Breadcrumb className={styles.breadcrumb} items={breadcrumbs} />
        <h1 className={styles.title}>{f.title}</h1>
        <div className={styles.info_root}>
          <div className={styles.info_posted_at}>
            {(f.createdOn < f.updatedOn) && <UpdatedOn date={f.updatedOn} />}
            {(f.createdOn >= f.updatedOn) && <CreatedOn date={f.createdOn} />}
          </div>
          <Tags className={styles.info_tags} tags={tags} />
        </div>
        <div className={styles.thumbnail_content}>
          <Image className={styles.thumbnail} image={f.image} />
        </div>
        <div dangerouslySetInnerHTML={{ __html: node.html }} />
      </article>
    </div>
  )
}

export default BlogPost
