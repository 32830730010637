import { BlogConfigProps } from './interface'

export default (config: BlogConfigProps) => {
  const { profile, blog } = config

  return [
    {
      '@type': 'Person',
      name: profile.name,
      description: profile.name,
      image: {
        '@type': 'ImageObject',
        url: profile.imageUrl,
        width: 60,
        height: 60
      },
      url: blog.baseUrl
    },
    {
      '@type': 'thing',
      name: profile.name,
      sameAs: blog.title,
      url: blog.baseUrl,
      image: {
        '@type': 'ImageObject',
        url: blog.imageUrl,
        width: 60,
        height: 60
      }
    }
  ];
}
