import React from 'react'

import { SEOProps } from '../interface'
import createJOSNLDTag from '../createJSONLDTag'
import AppConfig from '@/config/app'
import BaseSEO from './BaseSEO'

interface PostSEOProps extends Required<Pick<SEOProps, 'type'| 'post'| 'thumbnailPath'>> {}

const PostSEO: React.FC<PostSEOProps> = (props) => {
  const {
    type,
    post,
    thumbnailPath,
  } = props

  const config = AppConfig()
  const JSONLDTag = createJOSNLDTag({ type, post, config })

  return (
    <BaseSEO {...{
      type,
      item: post,
      thumbnailPath,
      jsonLD: JSON.stringify(JSONLDTag)
    }} />
  )
}

export default PostSEO
