import { useState, useRef, useEffect, useCallback } from 'react'
import throttle from 'lodash.throttle'
import SweetScroll from 'sweet-scroll'

const RIPPLE_DURATION = 750;
const THROTTLE_DELAY = 100;

// https://ja.reactjs.org/docs/higher-order-components.html#convention-wrap-the-display-name-for-easy-debugging
const useHandleGoTop = (props) => {
  const [isActive, setIsActive] = useState(false)
  const [enabled, setEnabled] = useState(false)
  const [isRippleActive, setIsRippleActive] = useState(false)

  const rippleStyleRef = useRef(props.rippleStyle)
  const sizeRef = useRef(props.size)
  const boundary = useRef(props.boundary)
  const maxWidth = useRef(props.maxWidth)

  useEffect(
    () => {
      const handleScroll = () => {
        setIsActive(window.pageYOffset > boundary.current)
      }

      const handleResize = () => {
        if (maxWidth.current > 0) {
          setEnabled(!window.matchMedia(`(max-width: ${maxWidth.current}px)`).matches)
        } else {
          setEnabled(true)
        }
      }

      const throttleHandleScroll = throttle(handleScroll, THROTTLE_DELAY)
      const throttleHandleResize = throttle(handleResize, THROTTLE_DELAY)

      handleResize()
      window.addEventListener('scroll', throttleHandleScroll)
      window.addEventListener('resize', throttleHandleResize)

      return () => {
        if (typeof window !== 'undefined') {
          window.removeEventListener('scroll', throttleHandleScroll)
          window.removeEventListener('resize', throttleHandleResize)
        }
      }

    },
    []
  )

  const fmtProp = (value, unit = 'px') => {
    return typeof value === 'number' ? value + unit : value;
  }

  const onClick = useCallback(
    (e) => {
      if (!isRippleActive) {
        const target = e.target
        if (target) {
          const rect = target.getBoundingClientRect()
          const top = e.clientY - rect.top
          const left = e.clientX - rect.left
          const size = sizeRef.current
          rippleStyleRef.current.top = fmtProp(top - size)
          rippleStyleRef.current.left = fmtProp(left - size)
        }
        setIsRippleActive(true)
        throttle(() => { setIsRippleActive(false) }, RIPPLE_DURATION)
      }
      const scroller = new SweetScroll()
      scroller.to(0)
    },
    [isRippleActive]
  )

  return {
    isActive: isActive,
    enabled: enabled,
    isRippleActive: isRippleActive,
    rippleStyle: { ...props.rippleStyle, ...rippleStyleRef.current },
    onClick: onClick
  }
}


export default useHandleGoTop
