import { TagProps, BlogConfigProps } from './interface'
import { humanize } from '@/utils'

type TagJSONLDProps = {
  tag: TagProps
  config: BlogConfigProps
}

export default ({
  tag,
  config
}: TagJSONLDProps) => {

  const { blog } = config

  return [
    {
      '@context': 'http://schema.org',
      '@type': 'BreadcrumbList',
      itemListElement:
        [
          {
            '@type': 'ListItem',
            position: 1,
            item: {
              '@type': 'WebPage',
              '@id': blog.baseUrl,
              url: blog.baseUrl,
              name: blog.baseUrl,
            }
          },
          {
            '@type': 'ListItem',
            position: 2,
            item: {
              '@type': 'WebPage',
              '@id': tag.url,
              url: tag.url,
              name: humanize(tag.title)
            }
          }
        ]
    },
    {
      '@context': 'http://schema.org',
      '@type': 'WebPage',
      inLanguage: 'ja',
      name: humanize(tag.title),
      '@id': tag.url,
      url: tag.url
    }
  ]
}
