import React, { useEffect } from 'react'

const IFRAMELY_URL = 'https://cdn.iframe.ly/embed.js'
const IFRAMELY_ID = 'iframely-embed-script'

// [ref]  
// https://github.com/tterb/gatsby-plugin-disqus/blob/master/src/components/Disqus.jsx#L39-L52
const Iframely = () => {
  useEffect(
    () => {
      const loadInstance = () => {
        if (typeof window !== 'undefined' && window.document) {
          const script = window.document.createElement('script')
          const parent = window.document.body
          script.async = true
          script.src = IFRAMELY_URL
          script.id = IFRAMELY_ID
          parent.appendChild(script)
          return script
        }
      }

      const cleanInstance = () => {
        const parent = window.document.body
        const script = window.document.getElementById(IFRAMELY_ID)
        if (script) {
          parent.removeChild(script)
        }
      }

      if (typeof window !== 'undefined' && window.document) {
        loadInstance()
      }

      return () => {
        cleanInstance()
      }
    },
    []
  )

  return <></>
}

export default Iframely
