import React from 'react'

import { SEOProps } from '../interface'
import createJOSNLDTag from '../createJSONLDTag'
import AppConfig from '@/config/app'
import BaseSEO from './BaseSEO'

interface TagSEOProps extends Required<Pick<SEOProps, 'type' | 'tag' | 'thumbnailPath' >> { }

const TagSEO: React.FC<TagSEOProps> = (props) => {
  const {
    type,
    tag,
    thumbnailPath
  } = props

  const config = AppConfig()
  const JSONLDTag = createJOSNLDTag({ type, tag, config })

  return (
    <BaseSEO {...{
      type,
      item: tag,
      thumbnailPath,
      jsonLD: JSON.stringify(JSONLDTag)
    }} />
  )
}

export default TagSEO
