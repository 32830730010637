import React from 'react'

import { SEOProps } from '../interface'
import createJOSNLDTag from '../createJSONLDTag'
import AppConfig from '@/config/app'
import BaseSEO from './BaseSEO'

interface CategorySEOProps extends Required<Pick<SEOProps, 'type' | 'category' | 'thumbnailPath' >> {}

const CategorySEO: React.FC<CategorySEOProps> = (props) => {
  const {
    type,
    category,
    thumbnailPath,
  } = props

  const config = AppConfig()
  const JSONLDTag = createJOSNLDTag({ type, category, config })

  return (
    <BaseSEO {...{
      type,
      item: category,
      thumbnailPath,
      jsonLD: JSON.stringify(JSONLDTag)
    }} />
  )
}

export default CategorySEO
