// [ref]
// https://github.com/Takumon/blog/tree/master/src/components/toc

import React from 'react'
import { Link } from 'gatsby'
import { WindowLocation } from '@reach/router'
import useHandleToc from './useHandleToc'

import * as styles from './PostToc.module.scss';

type Props = {
  headings: any
  className?: string
  location: WindowLocation
}

const PostToc: React.FC<Props> = (props) => {
  const { headings, className, location } = props
  const { activeItemIds } = useHandleToc(props)
  const pathname = location.pathname

  // MEMO:
  // position: surrounded by div to make sticky work
  return (
    <div className={className}>
      {headings.length > 0 &&
        <div className={styles.root}>
          <div className={styles.title}>目次</div>
          <div className={styles.content}>
            <ul>
              {
                headings.map((item: any) => (
                  <li key={item.id} style={{ marginLeft: `${(item.depth - 2) * 12}px` }}>
                    <Link
                      to={`${pathname}#${item.id}`}
                      className={(activeItemIds || []).includes(item.id) ? styles.active : ''}>{item.value}</Link>
                  </li>
                ))
              }
            </ul>
          </div>
        </div>}
    </div>
  )
}

export default PostToc
