import Typography from 'typography'
import theme from 'typography-theme-ocean-beach'
import { colors } from '@styles/variables'

theme.googleFonts.push({
  name: 'Noto+Sans+JP',
  styles: ['400']
})
theme.bodyFontFamily = ['Noto Sans JP', 'Roboto', 'serif']
theme.overrideThemeStyles = ({ rhythm }, options) => ({
  html: {
    fontSize: '62.5% !important',
    backgroundColor: `${colors.background}`
  },
  '@media screen and (max-width: 650px){html{font-size: 50%;}}':{},
  pre: {
    padding: rhythm(0.6),
    overflowX: 'auto',
    overflowY: 'hidden'
  },
  a: {
    color: `${colors.brand}`,
    textShadow: 'none',
    textDecoration: 'none',
    backgroundImage: 'none'
  },
  blockquote: {
    paddingLeft: '1rem',
    borderLeft: `0.5rem solid ${colors.whiteGray}`
  }
})

const typography = new Typography(theme)
if (process.env.GATSBY_NODE_ENV !== 'production') {
  typography.injectStyles()
}

export default typography
export const rhythm = typography.rhythm
export const scale = typography.scale
