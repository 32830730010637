import { CategoryProps, BlogConfigProps } from './interface'
import { humanize } from '@/utils'

type CateogryJSONLDProps = {
  category: CategoryProps
  config: BlogConfigProps
}

export default ({
  category,
  config
}: CateogryJSONLDProps) => {

  const { blog } = config

  return [
    {
      '@context': 'http://schema.org',
      '@type': 'BreadcrumbList',
      itemListElement:
        [
          {
            '@type': 'ListItem',
            position: 1,
            item: {
              '@type': 'WebPage',
              '@id': blog.baseUrl,
              url: blog.baseUrl,
              name: blog.baseUrl,
            }
          },
          {
            '@type': 'ListItem',
            position: 2,
            item: {
              '@type': 'WebPage',
              '@id': category.url,
              url: category.url,
              name: humanize(category.title)
            }
          }
        ]
    },
    {
      '@context': 'http://schema.org',
      '@type': 'WebPage',
      inLanguage: 'ja',
      name: humanize(category.title),
      '@id': category.url,
      url: category.url
    }
  ]
}
