import React from 'react'
import dayjs from 'dayjs'
import { Icon } from '@/components/atoms'

import * as styles from './UpdatedOn.module.scss'

type Props = {
  date: Date
  containerStyle?: any
}

const UpdatedOn: React.FC<Props> = ({ date, containerStyle = {} }) => (
  <div className={styles.root} style={{ ...containerStyle }}>
    <Icon icon="updated_at" size={"lg"} />
    <time className={styles.date} dateTime={date}>
      {dayjs(date).format('YYYY年MM月DD日')} 更新
    </time>
  </div>
)

export default UpdatedOn
