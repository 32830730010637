// [ref]
// https://github.com/Takumon/blog/blob/master/src/components/sns-share/index.js

import React from 'react'
import Helmet from 'react-helmet'
import {
  FacebookShareButton,
  FacebookIcon,
  LinkedinShareButton,
  LinkedinIcon,
  TwitterShareButton,
  TwitterIcon,
  PocketIcon,
  PocketShareButton
} from 'react-share'
import ClassNames from 'classnames'

import useHandleSNSShare from './useHandleSNSShare'
import * as styles from './SNSShare.module.scss'

type Props = {
  title: string
  link: string
  overrideClassName?: string
  size?: number
}

// [ref]
// https://qiita.com/phanect/items/82c85ea4b8f9c373d684
const SNSShare: React.FC<Props> = ({ overrideClassName, title, link, size = 32 }) => {

  const { isShowSnsShare } = useHandleSNSShare()
  const rootClassName = overrideClassName ?
    ClassNames({
      [overrideClassName!]: true
    })
    :
    ClassNames({
      [`${styles.sns_share_root}`]: true,
      [`${styles.sns_share_show}`]: isShowSnsShare,
      [`${styles.sns_share_hide}`]: !isShowSnsShare
    })

  return (
    <div className={rootClassName} >
      <ul className={styles.content}>
        <Helmet>
          <script type="text/javascript" src="//b.st-hatena.com/js/bookmark_button.js" charSet="utf-8" defer />
          <script type="text/javascript" src="//widgets.getpocket.com/v1/j/btn.js?v=1" charSet="utf-8" defer />
        </Helmet>

        <li className={styles.share_button}>
          <TwitterShareButton title={title} url={link}>
            <TwitterIcon size={size} round />
          </TwitterShareButton>
        </li>

        <li className={styles.share_button}>
          <FacebookShareButton url={link}>
            <FacebookIcon size={size} round />
          </FacebookShareButton>
        </li>

        <li className={styles.share_button}>
          <LinkedinShareButton title={title} url={link}>
            <LinkedinIcon size={size} round />
          </LinkedinShareButton>
        </li>

        <li className={styles.share_button}>
          <PocketShareButton title={title} url={link}>
            <PocketIcon size={size} round />
          </PocketShareButton>
        </li>

        <li className={styles.share_button}>
          <a
            aria-label="share_hatena-bookmark"
            href={`https://b.hatena.ne.jp/entry/s/${(link || '').replace(/https|http\:\/\//, "")}`}
            target="_blank"
            className="harena-bookmark-button"
            data-hatena-bookmark-layout="touch-counter"
            title="このエントリーをはてなブックマークに追加"
            style={{ boxShadow: 'none' }}>
            <img
              src="https://b.st-hatena.com/images/entry-button/button-only@2x.png"
              alt="このエントリーをはてなブックマークに追加"
              width={size}
              height={size}
              loading="lazy"
              style={{ border: 'none' }} />
          </a>
        </li>
      </ul>
    </div>
  )
}

export default SNSShare
