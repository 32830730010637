import { useState, useRef, useEffect } from 'react'
import throttle from 'lodash.throttle'

const getElementTopOffsetsBy = (headings) => {
  return headings
    .map(({ id, parents }) => {
      const el = document.getElementById(id)
      return el
        ? {
          id,
          offsetTop: el.offsetTop,
          parents
        }
        : null
    })
    .filter(item => item)
}

const THROTTLE_DELAY_RESIZE = 500;
const THROTTLE_DELAY_SCROLL = 100;

const useHandleToc = (props) => {
  const [activeItemIds, setActiveItemIds] = useState([])
  const itemTopOffsetsRef = useRef([])
  const headingsRef = useRef(props.headings)

  useEffect(
    () => {
      const calculateItemTopOffsets = () => {
        const headings = headingsRef.current
        itemTopOffsetsRef.current = getElementTopOffsetsBy(headings)
      }

      const handleScroll = () => {
        const item = itemTopOffsetsRef.current.find((current, i) => {
          const next = itemTopOffsetsRef.current[i + 1]

          return next
            ? window.scrollY >= current.offsetTop && window.scrollY < next.offsetTop
            : window.scrollY >= current.offsetTop
        })

        const ids =
          item
            ? item.parents
              ? [item.id, ...item.parents.map(i => i.id)]
              : [item.id]
            : [];

        setActiveItemIds(ids)
      }

      const handleResize = () => {
        calculateItemTopOffsets()
        handleScroll()
      }

      const throttleHandleScroll = throttle(handleScroll, THROTTLE_DELAY_SCROLL)
      const throttleHandleResize = throttle(handleResize, THROTTLE_DELAY_RESIZE)

      calculateItemTopOffsets()
      window.addEventListener('resize', throttleHandleResize)
      window.addEventListener('scroll', throttleHandleScroll)

      // behave like componentWillMount
      return () => {
        if (typeof window !== 'undefined') {
          window.removeEventListener('resize', throttleHandleResize)
          window.removeEventListener('scroll', throttleHandleScroll)
        }
      }
    },
    []
  )

  return {
    activeItemIds
  }
}


export default useHandleToc
