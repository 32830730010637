import React from 'react'
import Img from 'gatsby-image'
import ClassNames from 'classnames'

import * as styles from './Image.module.scss'

type Props = {
  className?: any
  image: {
    publicURL: string
    childImageSharp: any
  }
  customStyle?: any
}

const Image: React.FC<Props> = ({ className, image, customStyle = { width: '100%' } }) => {
  const rootClassName = ClassNames({
    [styles.root]: true,
    [className!]: className
  })

  try {
    const { publicURL, childImageSharp } = image
    const alt: string = publicURL.split('/').slice(-1)[0]

    if (!childImageSharp) {
      return <img className={rootClassName} src={publicURL} loading="lazy" alt={alt} />
    }
    return (
      <div style={{ ...customStyle }}>
        <Img className={className} fluid={childImageSharp.fluid} alt={alt} />
      </div>
    )
  } catch (e) {
    return <span>Image Load Error</span>
  }
}

export default Image
