import React from 'react'
import { SvgIcon } from '@/components/atoms'

type SvgIconProps = {
  svgIconClassName?: string
  svgIconStyle?: any
  xlinkHref?: string
}

type Props = SvgIconProps & {
  href: string
  alt?: string
}

const SvgIconBtn: React.FC<Props> = (props) => {
  const { svgIconClassName, svgIconStyle, xlinkHref, href, alt } = props
  return (
    <a
      href={href}
      alt={alt}
      target={"_blank"}
      rel={"noopener noreferrer"}
    >
      <SvgIcon className={svgIconClassName} style={svgIconStyle} xlinkHref={xlinkHref} />
    </a>
  )
}

export default SvgIconBtn
