import React from 'react'
import ClassNames from 'classnames'
import { MarkdownRemarkEdge } from '@types/graphql-types'
import { PostCard, PostLine } from '@/components/molecules'

import { useHandleResponsible } from '@/hooks'
import * as styles from './RelatedPostCardList.module.scss'

type Props = {
  className?: string
  edges: MarkdownRemarkEdge[]
}

const RelatedPostCardList: React.FC<Props> = ({ className, edges }) => {
  const { changeDesign: switchToLine } = useHandleResponsible({ breakPoint: 1160 })
  const rootClassName = ClassNames({
    [styles.root]: true,
    [className!]: className
  })

  return (
    <>
      {edges.length > 0 &&
        <div className={rootClassName}>
          <h2 className={styles.title}>関連記事</h2>
          <div className={styles.content}>
            {edges.map(({ node }, index) => {
              const slug = node.fields.slug || node.frontmatter.slug
              return (
                <>
                  {switchToLine && <PostLine excerpt className={styles.post_line} key={`${slug}-${index}`} pageContext={{ node }} />}
                  {!switchToLine && <PostCard className={styles.post_card} key={`${slug}-${index}`} pageContext={{ node }} />}
                </>
              )
            })}
          </div>
        </div>
      }
    </>
  )
}

export default RelatedPostCardList
