import { CategoryProps, SubCategoryProps, BlogConfigProps } from './interface'
import { humanize } from '@/utils'

type SubCateogryJSONLDProps = {
  category: CategoryProps
  subcategory: SubCategoryProps
  config: BlogConfigProps
}

export default ({
  category,
  subcategory,
  config
}: SubCateogryJSONLDProps) => {

  const { blog } = config

  return [
    {
      '@context': 'http://schema.org',
      '@type': 'BreadcrumbList',
      itemListElement:
        [
          {
            '@type': 'ListItem',
            position: 1,
            item: {
              '@type': 'WebPage',
              '@id': blog.baseUrl,
              url: blog.baseUrl,
              name: blog.baseUrl,
            }
          },
          {
            '@type': 'ListItem',
            position: 2,
            item: {
              '@type': 'WebPage',
              '@id': category.url,
              url: category.url,
              name: humanize(category.title)
            }
          },
          {
            '@type': 'ListItem',
            position: 3,
            item: {
              '@type': 'WebPage',
              '@id': subcategory.url,
              url: subcategory.url,
              name: humanize(subcategory.title)
            }
          }
        ]
    },
    {
      '@context': 'http://schema.org',
      '@type': 'WebPage',
      inLanguage: 'ja',
      name: humanize(subcategory.title),
      '@id': subcategory.url,
      url: subcategory.url
    }
  ]
}
