import React from 'react'
import { Link } from 'gatsby'
import { MarkdownRemarkGroupConnection } from '@types/graphql-types'

import { humanize, machinize, pathOptimize } from '@/utils'
import categoryData from '@content/config/category.yml'

import * as styles from './CategorySubCategoryList.module.scss'

const remakeCategorySubCategory = (data: CategorySubCategory[]) => {
  return data.reduce((acc, item) => {
    acc[machinize(item.category)] = machinize(item.subcategory)
    return acc
  }, {})
}

type Props = {
  className?: string
  category: {
    group: MarkdownRemarkGroupConnection[]
  }
}

const CategorySubCategoryList: React.FC<Props> = ({ className, category }) => {
  const categorySubcategory = remakeCategorySubCategory(categoryData)

  return (
    <div className={className}>
      <ul className={styles.ul}>
        {
          category.group && category.group.map(({ fieldValue, totalCount }) => {
            const hcategory = humanize(fieldValue)
            const mcategory = machinize(fieldValue)
            return (
              <li key={mcategory}>
                <Link to={pathOptimize(`/category/${mcategory}`)}>
                  {hcategory} ({totalCount})
                </Link>
                {
                  categorySubcategory[mcategory] && (
                    <ul key={mcategory}>
                      {
                        categorySubcategory[mcategory].map((subcategory: string) => {
                          const hsubcategory = humanize(subcategory)
                          const msubcategory = machinize(subcategory)
                          return (
                            <li key={msubcategory}>
                              <Link to={pathOptimize(`/category/${mcategory}/${msubcategory}`)}>
                                {hsubcategory}
                              </Link>
                            </li>
                          )
                        })
                      }
                    </ul>
                  )
                }
                {!categorySubcategory[mcategory] && (
                  <Link to={pathOptimize(`/category/${mcategory}`)}>
                    {hcategory} ({totalCount})
                  </Link>
                )
                }
              </li>
            )
          })
        }
      </ul>
    </div>
  )
}

export default CategorySubCategoryList
