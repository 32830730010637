import React from 'react';

type Props = {
  className?: string
  style?: any
  src?: string
}

const PNGIcon: React.FC<Props> = (props) => {
  const { className, style, src } = props
  return (
    <img className={className} style={style} src={src} />
  )
}

export default PNGIcon
