import React from 'react'
import PropTypes from 'prop-types'
import ClassNames from 'classnames'

import { Icon } from '@/components/atoms'
import * as styles from './SearchResultTitle.module.scss'

type Props = {
  className?: string
  text?: string
  children?: any
}

const SearchResultTitle: React.FC<Props> = ({ className, text, children }) => {
  const rootClassName = ClassNames({
    [styles.root]: true,
    [className!]: className
  })
  return (
    <div className={rootClassName}>
      <div className={styles.title_root}>
        <Icon icon={"search"} size={"sm"} className={styles.title_icon} />
        <h1 className={styles.title_title}>
          「{text}」の検索結果
        </h1>
      </div>
      <div>{children}</div>
    </div>
  )
}

SearchResultTitle.propTypes = {
  className: PropTypes.string,
  text: PropTypes.string,
  children: PropTypes.any
}

export default SearchResultTitle
