import React from 'react';
import { PNGIcon } from '@/components/atoms'

type PNGIconProps = {
  pngIconClassName?: string
  pngIconStyle?: any
  pngHref: string
}

type Props = PNGIconProps & {
  href: string
  alt?: string
}

const PNGIconBtn: React.FC<Props> = (props) => {
  const { pngIconClassName, pngIconStyle, pngHref, href, alt } = props;
  return (
    <a
      href={href}
      alt={alt}
      target={"_blank"}
      rel={"noopener noreferrer"}
    >
      <PNGIcon className={pngIconClassName} style={pngIconStyle} src={pngHref} />
    </a>
  )
}

export default PNGIconBtn
