import React from 'react'
import Tippy from '@tippyjs/react'
import 'tippy.js/dist/tippy.css'

import { SvgIconBtn, PNGIconBtn } from '@/components/molecules'
import * as styles from './ProfileMenu.module.scss'
import items from '@content/config/profileMenu.yml'

type Item = {
  imgHref: string
  href: string
  alt: string
  type: | 'svg' | 'png'
}

type Props = {
  className?: string
}

const ProfileMenu: React.FC<Props> = ({ className }) => {
  return (
    <div className={className}>
      {items.length > 0 && items.map((item: Item) => (
        <Tippy content={item.alt} placement={"top"}>
          <div className={styles.icon_btn}>
            <span tabIndex="0">
              {item.type === 'svg' && <SvgIconBtn svgIconClassName={styles.icon_btn_item} href={item.href} alt={item.alt} xlinkHref={item.imgHref} />}
              {item.type === 'png' && <PNGIconBtn pngIconClassName={styles.icon_btn_item} href={item.href} alt={item.alt} pngHref={item.imgHref} />}
            </span>
          </div>
        </Tippy>
      ))
      }
    </div >

  )
}

export default ProfileMenu
