import React from 'react'
import dayjs from 'dayjs'
import { Icon } from '@/components/atoms'

import * as styles from './CreatedOn.module.scss'

type Props = {
  date: Date
  containerStyle?: any
}

const CreatedOn: React.FC<Props> = ({ date, containerStyle = {} }) => (
  <div className={styles.root} style={{ ...containerStyle }}>
    <Icon icon="created_at" size={"lg"} />
    <time className={styles.date} dateTime={date}>
      {dayjs(date).format('YYYY年MM月DD日')} 投稿
    </time>
  </div>
)

export default CreatedOn
