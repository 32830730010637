import React from 'react'
import { Link } from 'gatsby'
import ClassNames from 'classnames'

import { Icon } from '@/components/atoms'

import * as styles from './IconLink.module.scss'

type Props = {
  item: {
    icon: string
    name: string
    link: string
  }
  type: 'a' | 'Link'
  linkStyle?: any,
  className?: string
  iconClassName?: string
  size?:
  | 'xs'
  | 'sm'
  | 'lg'
  | '2x'
  | '3x'
  | '4x'
  | '5x'
  | '6x'
  | '7x'
  | '8x'
  | '9x'
  | '10x'
}

const IconLink: React.FC<Props> = ({
  item,
  type = 'Link',
  className,
  linkStyle = {},
  size,
  iconClassName = styles.icon_default
}) => {
  const rootClassName = ClassNames({
    [styles.root]: true,
    [className!]: className
  })

  return (
    <div className={rootClassName}>
      <Icon icon={item.icon} className={iconClassName} size={size} />
      {type === 'a' && <a className={styles.a} href={item.link} style={linkStyle}>{item.name}</a>}
      {type === 'Link' && <Link className={styles.link} to={item.link} style={linkStyle}>{item.name}</Link>}
    </div>
  )
}

export default IconLink
