import React from 'react'
import { Link } from 'gatsby'
import ClassNames from 'classnames'
import { pathOptimize } from '@/utils'

import * as styles from './Subcategories.module.scss'

type Props = {
  className?: string
  category: string
  items: string[]
}

const Subcategories: React.FC<Props> = ({ className, category, items }) => {
  const rootClassName = ClassNames({
    [styles.root]: true,
    [className!]: className
  })

  return (
    <div className={rootClassName}>
      {items &&
        items.map(item => {
          return (
            <Link className={styles.link} key={item} to={pathOptimize(`/category/${category}/${item}`)}>
              {item}
            </Link>
          )
        })}
    </div>
  )
}

export default Subcategories
