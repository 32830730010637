import React from 'react'
import ClassNames from 'classnames'

import { Icon } from '@/components/atoms'
import { MarkdownRemarkEdge } from '@types/graphql-types'
import { PostLine } from '@/components/molecules'

import * as styles from './LatestPostLineList.module.scss'

// Sort including update date and time
const reSortNodes = (edges: MarkdownRemarkEdge[]) => {
  const sortedOn = edges.reduce((acc, { node }) => {
    const f = node.frontmatter
    if (f.createdOn < f.updatedOn) {
      acc.push({ on: f.updatedOn, node, type: 'updatedOn' })
    } else {
      acc.push({ on: f.createdOn, node, type: 'createdOn' })
    }
    return acc
  }, [])

  const sorted = sortedOn.sort((a: any, b: any) => {
    if (a.on > b.on) return -1
    if (a.on < b.on) return 1
    if (a.on === b.on) {
      if (a.type === 'updatedOn') return -1
      if (a.type === 'createdOn') return 1
    }
    return 0
  })
  return sorted.map(item => item.node)
}

type Props = {
  className?: string
  edges: MarkdownRemarkEdge[]
}

const PostCardList: React.FC<Props> = ({ className, edges }) => {
  const rootClassName = ClassNames({
    [styles.root]: true,
    [className!]: className
  })

  return (
    <div className={rootClassName}>
      <span className={styles.title_root}>
        <Icon icon={"star"} size={"sm"} className={styles.title_icon} />
        <span className={styles.title_title}>最新記事</span>
      </span>
      <div className={styles.content_root}>
        <ul className={styles.content_ul}>
          {reSortNodes(edges).slice(0, 5).map(node => (
            <li className={styles.content_li} key={node.id}>
              <PostLine key={node.fields.slug} pageContext={{ node }} />
            </li>
          ))}
        </ul>
      </div>
    </div>
  )
}

export default PostCardList
