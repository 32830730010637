import { useState, useEffect } from 'react'
import throttle from 'lodash.throttle'

const THROTTLE_DELAY_RESIZE = 100;

type Props = {
  breakPoint: number
}

const useHandleResponsible = ({ breakPoint }: Props) => {
  const [changeDesign, setChangeDesign] = useState(false)
  const [snsIconSize, setSnsIconSize] = useState(0)
  const [goTopSize, setGoTopIconSize] = useState(0)

  useEffect(
    () => {
      const handleResize = () => {
        setChangeDesign(window.innerWidth < breakPoint)
        setSnsIconSize(parseInt(window.innerWidth / (3 * 4)))
        setGoTopIconSize(parseInt(document.getElementsByTagName('footer')[0].clientHeight / 2))
      }

      const throttleHandleResize = throttle(handleResize, THROTTLE_DELAY_RESIZE)

      if (typeof window !== 'undefined') {
        handleResize()
        window.addEventListener('resize', throttleHandleResize)
      }

      return () => {
        if (typeof window !== 'undefined') {
          window.removeEventListener('resize', throttleHandleResize)
        }
      }
    },
    []
  )

  return {
    changeDesign,
    snsIconSize,
    goTopSize
  }
}

export default useHandleResponsible
