import React from 'react'
import { Link } from 'gatsby'
import { Hit } from 'react-instantsearch-core'
import Highlighter from 'react-highlight-words'
import ClassNames from 'classnames'

import {
  UpdatedOn,
  CreatedOn,
  Tags
} from '@/components/molecules'
import { SearchDocument } from '../interface'
import * as styles from './SearchHit.module.scss'

type SearchHitProps<T> = {
  className?: string
  hit: Hit<T>
}

const SearchHit: React.FC<SearchHitProps<SearchDocument>> = ({
  className,
  hit: {
    _highlightResult: highlight,
    title,
    slug,
    createdOn,
    updatedOn,
    excerpt,
    tags
  }
}) => {
  const rootClassName = ClassNames({
    [styles.root]: true,
    [className!]: className
  })

  return (
    <div className={rootClassName}>
      <Link to={slug} className={styles.link_root}>
        <h2 className={styles.link_title}>
          {highlight.title && highlight.title.matchLevel !== 'none' ? (
            <Highlighter
              highlightClassName={styles.highlight}
              searchWords={highlight.title.matchedWords}
              textToHighlight={title}
            />
          ) : title}
        </h2>
        <div className={styles.supplement_root}>
          {(createdOn < updatedOn) && <UpdatedOn date={updatedOn} containerStyle={{ fontSize: '0.7em' }} />}
          {(createdOn >= updatedOn) && <CreatedOn date={createdOn} containerStyle={{ fontSize: '0.7em' }} />}
          <Tags className={styles.tags} tags={tags} />
        </div>
        <p>
          {highlight.excerpt && highlight.excerpt.matchLevel !== 'none' ? (
            <Highlighter
              highlightClassName={styles.highlight}
              searchWords={highlight.excerpt.matchedWords}
              textToHighlight={excerpt}
            />
          ) : excerpt}
        </p>
      </Link>
    </div>
  )
}

export default SearchHit
