import React from 'react'
import ClassNames from 'classnames'
import { Breadcrumb } from '@/components/atoms'

import * as styles from './TagBoard.module.scss'

type Props = {
  className?: string
  title: string
  totalCount: number
  breadcrumbs: any
}

const TagBoard: React.FC<Props> = ({
  className,
  title,
  totalCount,
  breadcrumbs
}) => {
  const rootClassName = ClassNames({
    [styles.root]: true,
    [className!]: className
  })

  return (
    <div className={rootClassName}>
      <Breadcrumb className={styles.breadcrumb} items={breadcrumbs} />
      <h2>「#{title}」の記事一覧 ({totalCount}件)</h2>
    </div>
  )
}

  export default TagBoard
