import React from 'react'
import { Image, SvgIcon } from '@/components/atoms'
import { ProfileMenu } from '@/components/organisms'

import { useHandleResponsible } from '@/hooks'
import * as styles_lg from './PersonWhoWroteLarge.module.scss'
import * as styles_sm from './PersonWhoWroteSmall.module.scss'

type Props = {
  className?: string
  profile: {
    name: string
    description: string
    backgroundImg: any
  }
  blog: {
    description: string
  }
}

type MakeProps = Props & {
  styles: any
}

const MakePersonWhoWrote: React.FC<MakeProps> = ({ styles, className, profile, blog }) => {
  return (
    <div className={className}>
      <div className={styles.root}>
        <div className={styles.image_root}>
          <SvgIcon className={styles.profile_image} xlinkHref={"svg-sprites_icon-yukihirop"} />
          <Image className={styles.background_image} image={profile.backgroundImg} />
          <h3 className={styles.profile_name}>{profile.name}</h3>
        </div>
        <div className={styles.content_root}>
          <h2 className={styles.content_title}>この記事を書いた人</h2>
          <p className={styles.content_description}>{profile.description}</p>
          <p className={styles.content_description}>{blog.description}</p>
          <ProfileMenu className={styles.content_profile_menu} />
        </div>
      </div>
    </div>
  )
}

const PersonWhoWrote: React.FC<Props> = ({ className, profile, blog }) => {
  const { changeDesign } = useHandleResponsible({ breakPoint: 855 })

  return (
    <>
      {!changeDesign && <MakePersonWhoWrote {...{ styles: styles_lg, className, profile, blog }} />}
      {changeDesign && <MakePersonWhoWrote {...{ styles: styles_sm, className, profile, blog }} />}
    </>
  )
}

export default PersonWhoWrote
