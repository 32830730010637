import * as styles from './FormGroupStatus.module.scss'

export default {
  sending: {
    icon: 'circle',
    message: 'お問い合わせの送信しています……',
    className: styles.status_sending
  },
  error: {
    icon: 'times',
    message: 'お問い合わせの送信ができませんでした',
    className: styles.status_error
  }
}
