import { BlogConfigProps } from './interface'

type BlogPostProps = {
  config: BlogConfigProps
}

export default ({
  config
}: BlogPostProps) => {
  const { blog } = config

  return[{
      '@context': 'http://schema.org',
      '@type': 'WebSite',
      inLanguage: 'ja',
      url: blog.baseUrl,
      name: blog.title,
      alternateName: blog.title,
      image: blog.imageUrl,
      description: blog.description
    }
  ]
}
