import React from 'react'

import { SEOProps } from '../interface'
import createJOSNLDTag from '../createJSONLDTag'
import AppConfig from '@/config/app'
import BaseSEO from './BaseSEO'

interface SubCategorySEOProps extends Required<Pick<SEOProps, 'type' | 'other' | 'thumbnailPath'>> {
}

const SubCategorySEO: React.FC<SubCategorySEOProps> = (props) => {
  const {
    type,
    other,
    thumbnailPath,
  } = props

  const config = AppConfig()
  const JSONLDTag = createJOSNLDTag({ type, config })

  return (
    <BaseSEO {...{
      type,
      item: other,
      thumbnailPath,
      jsonLD: JSON.stringify(JSONLDTag)
    }} />
  )
}

export default SubCategorySEO
