import React from 'react'
import { StateResultsProvided } from 'react-instantsearch-core'
import { Hits, PoweredBy, connectStateResults } from 'react-instantsearch-dom'
import ClassNames from 'classnames'

import { SearchResultTitle } from '@/components/molecules'
import SearchHit from './SearchHit'
import { SearchDocument } from './interface'
import * as styles from './SearchResult.module.scss'

type SearchResultProps = StateResultsProvided<SearchDocument> & {
  className?: string
}

const SearchResult: React.FC<SearchResultProps> = ({ className, searchState: { query: text }, searchResults }) => {
  const hasResults = searchResults && searchResults.nbHits !== 0;
  const rootClassName = ClassNames({
    [styles.root]: true,
    [className!]: className
  })

  return (
    <div className={rootClassName}>
      <SearchResultTitle className={styles.title_root} text={text}>
        <PoweredBy className={styles.powered_by} />
      </SearchResultTitle>
      <article className={styles.article_root}>
        {text && hasResults && <Hits hitComponent={SearchHit} />}
        {text && !hasResults &&
          <div>
            <p>「{text}」は見つかりませんでした</p>
          </div>
        }
      </article>
    </div>
  )
}

export default connectStateResults(SearchResult)

