import React from 'react'
import { SEOProps } from './interface'
import {
  PostSEO,
  CategorySEO,
  SubCategorySEO,
  TagSEO,
  OtherSEO
} from './SEOs'

const SEO: React.FC<SEOProps> = (props) => {
  const {
    type,
    pageType,
    post,
    category,
    subcategory,
    tag,
    other,
    thumbnailPath
  } = props

  const seo = (pageType: string) => {
    switch (pageType) {
      case 'post':
        return <PostSEO {...{ type, post, thumbnailPath }} />
      case 'category':
        return <CategorySEO {...{ type, category, thumbnailPath }} />
      case 'subcategory':
        return <SubCategorySEO {...{ type, category, subcategory, thumbnailPath }} />
      case 'tag':
        return <TagSEO {...{ type, tag, thumbnailPath }} />
      default:
        return <OtherSEO {...{ type, other, thumbnailPath }} />
    }
  }

  return seo(pageType)
}

export default SEO
