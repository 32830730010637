import {
  createAuthor,
  createPublisher,
  createBlogPost,
  createWebSite,
  createCategory,
  createSubCategory,
  createTag
} from './JSONLD'

import { JSONLDProps } from './JSONLD/interface';

// [ref]
// https://webtan.impress.co.jp/e/2019/08/05/33500
export default ({
  type,
  post,
  category,
  subcategory,
  tag,
  config
}: JSONLDProps) => {
  const author = createAuthor(config)
  const publisher = createPublisher(config)
  const website = createWebSite({ config })
  const graph = [...website, ...author, ...publisher]

  switch (type) {
    case 'article':
      const blogPost: Array<any> = createBlogPost({ post: post!, author, publisher, config })
      graph.push(...blogPost)
      break
    case 'category':
      const categoryPage: Array<any> = createCategory({ category: category!, config })
      graph.push(...categoryPage)
      break
    case 'subcategory':
      const subCategoryPage: Array<any> = createSubCategory({ category: category!, subcategory: subcategory!, config })
      graph.push(...subCategoryPage)
      break
    case 'tag':
      const tagPage: Array<any> = createTag({ tag: tag!, config })
      graph.push(...tagPage)
      break
  }

  return {
    '@context': 'http://schema.org',
    '@graph': graph
  }
}
