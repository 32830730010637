import React from 'react'
import { MarkdownRemarkEdge } from '@types/graphql-types'
import { PostCard, PostLine } from '@/components/molecules'

import { useHandleResponsible } from '@/hooks'
import * as styles from './PostCardList.module.scss'

type Props = {
  className?: string
  edges: MarkdownRemarkEdge[]
}

const PostCardList: React.FC<Props> = ({ className, edges }) => {
  const { changeDesign: switchToLine } = useHandleResponsible({ breakPoint: 1160})

  return (
    <div className={className}>
      <div className={styles.root}>
        {
          edges.map(({ node }) => (
            <>
              {switchToLine && <PostLine className={styles.post_line} excerpt key={node.fields.slug} pageContext={{ node }} />}
              {!switchToLine && <PostCard className={styles.post_card} excerpt key={node.fields.slug} pageContext={{ node }} />}
            </>
          ))
        }
      </div>
    </div>
  )
}

export default PostCardList
