import React from 'react'
import { Link } from 'gatsby'
import { MarkdownRemarkGroupConnection } from '@types/graphql-types'

import { humanize, machinize, pathOptimize } from '@/utils'
import * as styles from './TagsWithCount.module.scss'

type Props = {
  className?: string
  tags: {
    group: MarkdownRemarkGroupConnection[]
  }
}

const TagsWithCount: React.FC<Props> = ({ tags, className }) => {
  return (
    <div className={className}>
      <div className={styles.root}>
        {tags.group &&
          tags.group.map(({ fieldValue, totalCount }) => {
            const htag = humanize(fieldValue)
            const mtag = machinize(fieldValue)
            return (
              <Link className={styles.link} key={mtag} to={pathOptimize(`/tags/${mtag}/`)}>
                #{htag} ({totalCount})
              </Link>
            )
          })}
      </div>
    </div>
  )
}

export default TagsWithCount
