import React from "react";
import ClassNames from "classnames";

import { Image, PNGIcon } from "@/components/atoms";
import * as styles from "./ProfileCard.module.scss";

type Props = {
  className?: string;
  profile: {
    name: string;
    description: string;
    backgroundImg: any;
  };
};

const ProfileCard: React.FC<Props> = ({ className, profile }) => {
  const rootClassName = ClassNames({
    [styles.root]: true,
    [className!]: className,
  });

  return (
    <div className={rootClassName}>
      <div className={styles.image_root}>
        <Image
          className={styles.background_image}
          image={profile.backgroundImg}
        />
        <PNGIcon
          className={styles.profile_image}
          src="/assets/images/yukihirop.png"
        />
      </div>
      <div className={styles.content_root}>
        <h3 className={styles.content_title}>{profile.name}</h3>
        <p className={styles.content_description}>{profile.description}</p>
      </div>
    </div>
  );
};

export default ProfileCard;
