import React from 'react'
import { InstantSearch } from 'react-instantsearch-dom'
import algoliasearch from 'algoliasearch/lite'

const Search: React.FC = ({ children }) => (
  <InstantSearch
    indexName={process.env.GATSBY_ALGOLIA_INDEXNAME as string}
    searchClient={algoliasearch(
      process.env.GATSBY_ALGOLIA_APPID as string,
      process.env.GATSBY_ALGOLIA_SEARCH_ONLY_API_KEY as string
    )}>
    {children}
  </InstantSearch>
)

export default Search
