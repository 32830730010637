import React from 'react'
import ClassNames from 'classnames'

import * as styles from './FormGroupInput.module.scss'

type FormGroupProps = {
  title: string
  type: 'email' | 'text'
  name: string
  value: string | number | string[]
  required?: boolean
  readOnly: boolean
  onChange?: (event: React.ChangeEvent<HTMLInputElement>) => void
}

const requiredLabelClassName = ClassNames({
  [styles.label]: true,
  [styles.required]: true
})

const FormGroupInput: React.FC<FormGroupProps> = (props) => {
  const { type, title, name, value, required, readOnly, onChange } = props
  return (
    <div>
      {required &&
        <div className={styles.root}>
          <label className={requiredLabelClassName}>{title}</label>
          <input
            className={styles.control}
            type={type}
            aria-invalid="false"
            name={name}
            value={value}
            onChange={onChange}
            readOnly={readOnly}
            required />
        </div>
      }
      {!required &&
        <div className={styles.root}>
          <label className={styles.label}>{title}</label>
          <input
            className={styles.control}
            type={type}
            aria-invalid="false"
            name={name}
            value={value}
            onChange={onChange}
            readOnly={readOnly} />
        </div>
      }
    </div>
  )
}

export default FormGroupInput
