import { useState, useEffect } from 'react'
import throttle from 'lodash.throttle'

const THRESHOLD_Y = 400
const THROTTLE_DELAY = 500;

const useHandleSNSShare = (props) => {
  const [isShowSnsShare, setIsShowSnsShare] = useState(false)

  useEffect(
    () => {
      const scrollTop = () => {
        return Math.max(
          window.pageYOffset,
          document.documentElement.scrollTop,
          document.body.scrollTop)
      }

      const watchCurrentPosition = () => {
        setIsShowSnsShare(scrollTop() > THRESHOLD_Y)
      }

      const throttleWatchCurrentPosition = throttle(watchCurrentPosition, THROTTLE_DELAY)

      window.addEventListener('scroll', throttleWatchCurrentPosition)

      // behave like componentWillMount
      return () => {
        if (typeof window !== 'undefined') {
          window.removeEventListener('scroll', throttleWatchCurrentPosition)
        }
      }
    },
    []
  )

  return {
    isShowSnsShare
  }
}

export default useHandleSNSShare
