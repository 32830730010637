import React from 'react'

type Props = {
  className?: string
  style?: any
  xlinkHref?: string
}

const SvgIcon: React.FC<Props> = (props) => {
  const { className, style, xlinkHref } = props
  return (
    <svg className={className} style={style}>
      <use xmlns={"http://www.w3.org/1999/xlink"} xlinkHref={`#${xlinkHref}`} />
    </svg >
  )
}

export default SvgIcon
