import translateData from '@content/config/translate.yml'

export const machinize = (text: string): string => {
  return translateData[text] || text
}

export const humanize = (text: string): string => {
  const reverseTranslateData = Object.entries(translateData).reduce(
    (acc: any, item: any) => {
      const el = { [item[1]]: item[0] }
      acc = Object.assign(acc, el)
      return acc
    },
    {}
  )
  return reverseTranslateData[text] || text
}
