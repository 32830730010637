import * as React from 'react'
import ClassNames from 'classnames'

import * as styles from './Container.module.scss'

interface ContainerProps {
  className?: string
}

const Container: React.FC<ContainerProps> = ({ children, className }) => {
  const classNames = ClassNames({
    [`${styles.root}`]: true,
    [`${className}`]: className
  })

  return (
    <div className={classNames}>{children}</div>
  )
}

export default Container
