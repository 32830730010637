import React from 'react'
import ClassNames from 'classnames'
import { Breadcrumb } from '@/components/atoms'
import { Subcategories } from '@/components/molecules'

import * as styles from './CategoryRelatedBoard.module.scss'

type Props = {
  className?: string
  title: string
  totalCount: number
  category: string
  subcategories: string[]
  breadcrumbs: any
}

const CategoryRelatedBoard: React.FC<Props> = ({
  className,
  title,
  totalCount,
  category,
  subcategories,
  breadcrumbs,
}) => {
  const rootClassName = ClassNames({
    [styles.root]: true,
    [className!]: className
  })

  return (
    <div className={rootClassName}>
      <Breadcrumb className={styles.breadcrumb} items={breadcrumbs} />
      <h1>「{title}」の記事一覧 ({totalCount}件)</h1>
      <Subcategories className={styles.subcategories} category={category} items={subcategories} />
    </div>
  )
}

export default CategoryRelatedBoard
