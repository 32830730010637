import React, { Suspense, lazy, useState, useCallback } from 'react'
import ClassNames from 'classnames'

import { SvgIcon } from '@/components/atoms'
import { IconLink } from '@/components/molecules'
import headerNav from '@content/config/headerNav.yml'
import { useHandleResponsible } from '@/hooks'

import * as styles from './Header.module.scss'

type Props = {
  className?: string
  siteTitle: string
  isTopPage?: boolean
}

const Header: React.FC<Props> = ({ className, siteTitle, isTopPage }) => {
  const [isOpen, setIsOpen] = useState(false)
  const { changeDesign: switchToIcon } = useHandleResponsible({ breakPoint: 1160 })

  const onClick = useCallback(
    (e: React.EventCallback) => {
      setIsOpen(!isOpen)
    },
    [isOpen]
  )

  const rootClassName = ClassNames({
    [styles.root]: true,
    [className!]: className
  })

  return (
    <header className={rootClassName}>
      <div className={styles.content_root}>
        <div className={styles.title_root}>
          {isTopPage && <a className={styles.title_link} href="/"><h1>{siteTitle}</h1></a>}
          {!isTopPage && <a className={styles.title_link} href="/">{siteTitle}</a>}
          {
            switchToIcon && (
              <div className={styles.nav_right_btn_root}>
                <span className={styles.nav_right_hamburger_root} onClick={onClick}>
                  {!isOpen && <SvgIcon className={styles.nav_right_hamburger_content} xlinkHref={"svg-sprites_icon-hamburger"} />}
                  {isOpen && <SvgIcon className={styles.nav_right_hamburger_content} xlinkHref={"svg-sprites_icon-times"} />}
                </span>
              </div>
            )
          }
        </div>
        <nav className={isOpen ? styles.nav_right_menu_humbergur_click : styles.nav_right_menu_root}>
          {headerNav.map((item: any) => {
            return <IconLink className={styles.nav_right_link} key={item.name} item={item} size={"2x"} linkStyle={{
              fontSize: '2rem',
              fontWeight: 'bold',
              color: 'white',
              padding: '0 0.5rem',
              textDecoration: 'none'
            }} />
          })}
        </nav>
      </div>
    </header>
  )
}

export default Header
