import React from 'react'
import { Link } from 'gatsby'
import ClassNames from 'classnames'

import { Image } from '@/components/atoms'
import { UpdatedOn, CreatedOn, Tags } from '@/components/molecules'
import { PostPageContext } from '@gatsby-node/createPostPages'

import * as styles from './PostLine.module.scss'

type Props = {
  excerpt?: boolean
  className?: string
  pageContext: PostPageContext
}

const PostLine: React.FC<Props> = ({ excerpt, className, pageContext }) => {
  const { node } = pageContext
  const f = node.frontmatter
  const slug = node.fields.slug || node.frontmatter.slug
  const rootClassName = ClassNames({
    [styles.root]: true,
    [className!]: className
  })

  return (
    <Link className={rootClassName} to={slug} key={`${slug}-link`}>
      <Image className={styles.image} image={f.image} customStyle={{ width: '30%' }} />
      <div className={styles.content_root}>
        <h2 className={styles.content_title}>{f.title}</h2>
        <div className={styles.supplement_root}>
          {(f.createdOn < f.updatedOn) && <UpdatedOn date={f.updatedOn} />}
          {(f.createdOn >= f.updatedOn) && <CreatedOn date={f.createdOn} />}
          {excerpt && <Tags className={styles.tags} tags={f.tags} limit={4} />}
        </div>
        {excerpt && <span className={styles.content_excerpt}>{node.excerpt}</span>}
      </div>
    </Link>
  )
}

export default PostLine
