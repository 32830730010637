import React, { useState, useCallback, useEffect } from 'react'
import { SearchBoxProvided } from 'react-instantsearch-core'
import { connectSearchBox } from 'react-instantsearch-dom'
import ClassNames from 'classnames'

import { Icon } from '@/components/atoms'
import * as styles from './SearchForm.module.scss'

interface SearchFormProps extends SearchBoxProvided {
  className?: string
  search?: boolean
  openSearch?: () => void
  closeSearch?: () => void
}

const SearchForm: React.FC<SearchFormProps> = ({ className, refine }) => {
  const [text, setText] = useState(null as string | null)

  const onKeyUp = useCallback((e: React.KeyboardEvent<HTMLInputElement>) => {
    e.preventDefault()
  }, [])

  const onChange = useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
    setText(e.currentTarget.value)
  }, [])

  useEffect(() => {
    refine(text)
  }, [text, refine])

  const rootClassName = ClassNames({
    [styles.root]: true,
    [className!]: className
  })

  return (
    <div className={rootClassName}>
      <Icon className={styles.icon_root} icon={"search"} />
      <input
        className={styles.input_root}
        type="text"
        placeholder="Search..."
        aria-label="search"
        value={text ?? ''}
        onKeyUp={onKeyUp}
        onChange={onChange}
      />
    </div>
  )
}

export default connectSearchBox(SearchForm)
