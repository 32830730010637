import React from 'react'
import { Icon } from '@/components/atoms'

import config from './config'
import * as styles from './FormGroupStatus.module.scss'

export type Status = '' | 'sending' | 'error'
type FormGroupStatusProps = {
  status: Status
}

const FormGroupStatus: React.FC<FormGroupStatusProps> = (props) => {
  const { status } = props

  const getIcon = (status: string) => status === '' ? undefined : config[status].icon
  const getStatusMessage = (status: string) => status === '' ? '' : config[status].message
  const getClassNaame = (status: string) => status === '' ? '' : config[status].className

  return (
    <div className={styles.root}>
      {status !== '' &&
        <div className={styles.area}>
          <Icon className={getClassNaame(status)} size={"xs"} icon={getIcon(status)} />
          <p>{getStatusMessage(status)}</p>
        </div>
      }
    </div>
  )
}
export default FormGroupStatus
