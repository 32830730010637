import { PostProps, BlogConfigProps } from './interface'

type BlogPostJSONLDProps = {
  post: PostProps,
  author: any,
  publisher: any
  config: BlogConfigProps
}

export default ({
  post,
  author,
  publisher,
  config
}: BlogPostJSONLDProps) => {

  const { blog } = config

  return [{
    '@context': 'http://schema.org',
    '@type': 'BreadcrumbList',
    itemListElement:
      [
        {
          '@type': 'ListItem',
          position: 1,
          name: post.title,
          item: post.url
        }
      ]
  },
  {
    '@context': 'http://schema.org',
    '@type': 'BlogPosting',
    url: post.url,
    name: post.title,
    alternateName: post.title,
    headline: post.title,
    image: {
      '@type': 'ImageObject',
      url: post.imageUrl
    },
    description: blog.description,
    datePublished: post.createdOn,
    dateModified: post.updatedOn,
    mainEntityOfPage: {
      '@type': 'WebPage',
      '@id': blog.baseUrl
    },
    author,
    publisher
  }]
}
