// [ref]
// https://github.com/inotom/vue-go-top/blob/master/src/components/GoTop.vue

import React from 'react'
import ClassNames from 'classnames'

import useHandleGoTop from './useHandleGoTop'
import * as styles from './GoTop.module.scss'

const GAP_FROM_SNS_SHARE = 7

type HooksProps = {
  isActive?: boolean
  enabled?: boolean
  isRippleActive?: boolean
  boundary?: number
  maxWidth?: number
  onClick?: any
}

const fmtProp = (value: string | number, unit = 'px') => {
  return typeof value === 'number' ? value + unit : value;
}

const makeRippleStyle = (size: string | number, rippleBg: string) => {
  return {
    top: fmtProp(-size),
    left: fmtProp(-size),
    width: '200%',
    height: '200%',
    backgroundColor: rippleBg
  }
}

type Props = HooksProps & {
  className?: string
  src?: string
  alt?: string
  size: number
  right: string | number
  bottom: string | number
  bottomGap: string | number
  zIndex: number
  fgColor: string
  bgColor: string
  radius: string | number
  weight: string
  boxShadow: string
  tabIndex: number,
  rippleBg: string
  rippleStyle: any
}

const GoTop: React.FC<Props> = (props) => {
  const {
    className,
    size,
    right,
    bottom,
    bottomGap,
    zIndex,
    fgColor,
    bgColor,
    radius,
    weight,
    boxShadow,
    tabIndex,
    src,
    alt,
    children
  } = props

  const {
    isActive,
    enabled,
    isRippleActive,
    rippleStyle,
    onClick
  } = useHandleGoTop(props)

  const bgImage = () => {
    if (src !== undefined) {
      return 'none';
    }
    return `url("${makeBgImage(weight, fgColor)}")`;
  }

  const makeBgImage = (weight: string, fgColor: string) => {
    const color = fgColor.replace(/^#/, '');
    if (weight === 'bold') {
      return 'data:image/svg+xml;charset=utf8, %3Csvg version=%271.1%27 xmlns=%27http://www.w3.org/2000/svg%27 width=%2710px%27 height=%2710px%27%3E %3Cpolygon fill=%27%23' + color + '%27 stroke=%27none%27 points=%278.646,7.354 5,3.707 1.354,7.354 0.646,6.646 5,2.293 9.354,6.646%27 /%3E %3C/svg%3E';
    }
    return 'data:image/svg+xml;charset=utf8,%3Csvg version=%271.1%27 xmlns=%27http://www.w3.org/2000/svg%27 width=%2710px%27 height=%2710px%27%3E %3Cpolygon fill=%27%23' + color + '%27 stroke=%27none%27 points=%278.589,6.945 5,3.22 1.413,6.945 1.052,6.598 5,2.499 8.948,6.598%27 /%3E %3C/svg%3E';
  };

  const _mainStyle = {
    width: fmtProp(size),
    height: fmtProp(size),
    right: fmtProp(right),
    bottom: `calc(${fmtProp(bottom)} - ${fmtProp(bottomGap)})`,
    zIndex: zIndex,
    color: fgColor,
    backgroundColor: bgColor,
    backgroundImage: bgImage(),
    boxShadow: boxShadow,
    borderRadius: fmtProp(radius),
    outline: '0 none',
  }

  const rightRootClassName = ClassNames({
    [`${styles.right_root}`]: true,
    [`${styles.root_show}`]: isActive && enabled,
    [`${styles.root_hide}`]: !(isActive && enabled)
  });

  const leftRootClassName = ClassNames({
    [`${styles.left_root}`]: true,
    [`${styles.root_show}`]: isActive && enabled,
    [`${styles.root_hide}`]: !(isActive && enabled)
  })

  return (
    <div className={className}>
      <div className={rightRootClassName} style={_mainStyle} onClick={onClick} role="button" tabIndex={tabIndex}>
        {(src !== undefined) &&
          <div className={styles.icon}>
            <img src={src} alt={alt} className={styles.image} />
            {isRippleActive &&
              <div className={styles.ripple} style={rippleStyle} />
            }
          </div>
        }
        {(src === undefined) &&
          <div className={styles.icon}>
            <div className={styles.content} />
            {isRippleActive &&
              <div className={styles.ripple} style={rippleStyle} />
            }
          </div>
        }
      </div>
      <div className={leftRootClassName}>
        {children}
      </div>
    </div>
  )
}

GoTop.defaultProps = {
  size: 70,
  right: 10,
  bottom: GAP_FROM_SNS_SHARE,
  bottomGap: 0,
  zIndex: 1000,
  fgColor: '#fff',
  bgColor: '#9d7cbf',
  radius: '50%',
  weight: 'bold',
  boxShadow: '1px 1px 2px rgba(0, 0, 0, .3)',
  tabIndex: 0,
  alt: '',
  rippleBg: 'rgba(255, 255, 255, .5)',
  rippleStyle: makeRippleStyle(70, 'rgba(255, 255, 255, .5)'),
  boundary: 200,
  maxWidth: 0
}

export default GoTop

