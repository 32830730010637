import React from 'react'
import { Link } from 'gatsby'
import ClassNames from 'classnames'

import { useHandleResponsible } from '@/hooks'
import * as styles_lg from './PrevNextLarge.module.scss'
import * as styles_sm from './PrevNextSmall.module.scss'

type Props = {
  className?: string
  ulClassName?: string
  prev?: {
    title: string
    link: string
  },
  next?: {
    title: string
    link: string
  }
}

type MakeProps = Props & {
  styles: any
}

const MakePrevNext: React.FC<MakeProps> = ({ styles, className, ulClassName, prev, next }) => {
  const mergedUlClassName = ClassNames({
    [styles.ul]: true,
    [ulClassName!]: ulClassName
  })

  if (prev || next) {
    return (
      <div className={className}>
        <ul className={mergedUlClassName}>
          <li className={styles.li}>
            {prev &&
              <Link className={styles.prev_link} to={prev!.link}>
                <p className={styles.prev_title}>
                  <span className={styles.prev_icon}>«</span>
                  <span>{prev!.title}</span>
                </p>
              </Link>
            }
          </li>
          <li className={styles.li}>
            {next &&
              <Link className={styles.next_link} to={next!.link}>
                <p className={styles.next_title}>
                  <span>{next!.title}</span>
                  <span className={styles.next_icon}>»</span>
                </p>
              </Link>
            }
          </li>
        </ul>
      </div>
    )
  } else {
    return <></>
  }
}

const PrevNext: React.FC<Props> = ({ className, ulClassName, prev, next }) => {
  const { changeDesign } = useHandleResponsible({ breakPoint: 950 })

  if (changeDesign) {
    return <MakePrevNext {...{ styles: styles_sm, className, ulClassName, prev, next }} />
  } else {
    return <MakePrevNext {...{ styles: styles_lg, className, ulClassName, prev, next }} />
  }
}

export default PrevNext
