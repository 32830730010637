import { BlogConfigProps } from './interface'

export default (config: BlogConfigProps) => {
  const { profile, blog } = config

  return [{
    '@type': 'Organization',
    name: profile.name,
    description: profile.description,
    url: blog.baseUrl,
    logo: {
      '@type': 'ImageObject',
      url: profile.imageUrl,
      width: 60,
      height: 60
    }
  }]
}
