import React from 'react'
import { Link } from 'gatsby'
import ClassNames from 'classnames'

import { IconLink } from '@/components/molecules'
import footerNav from '@content/config/footerNav.yml'

import * as styles from './Footer.module.scss'

type Props = {
  className?: string
}

const Footer: React.FC<Props> = ({ className }) => {
  const filteredMenus = footerNav.filter(item => item.icon === undefined)
  const mainMenus = footerNav.filter(item => item.icon !== undefined)
  const rootClassName = ClassNames({
    [styles.root]: true,
    [className!]: className
  })

  return (
    <footer className={rootClassName}>
      <div className={styles.content_root}>
        <div className={styles.title_root}>
          {mainMenus.map(item => (
            <IconLink className={styles.title_link} size={"lg"} key={item.name} item={item} type="a"></IconLink>
          ))}
        </div>
        <nav className={styles.nav_root}>
          {filteredMenus.map(item => (
            <Link className={styles.nav_link} key={item.name} to={item.link}>{item.name}</Link>
          ))}
        </nav>
        <div className={styles.copyright}>
          Copyright ©︎ 2020 yukihirop Powered by Gatsby
        </div>
      </div>
    </footer>
  )
}

export default Footer
