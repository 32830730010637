import React from 'react'
import ClassNames from 'classnames'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  faStar,
  faTags,
  faList,
  faSearch,
  faCode,
  faRocket,
  faGamepad,
  faEnvelope,
  faHome,
  faHistory,
  faClock,
  faCircleNotch,
  faTimes,
  faCheck
} from '@fortawesome/free-solid-svg-icons'

import * as styles from './Icon.module.scss'

type Props = {
  icon:
  | 'updated_at'
  | 'created_at'
  | 'programing'
  | 'personal-development'
  | 'etc'
  | 'contact'
  | 'home'
  | 'star'
  | 'tag'
  | 'list'
  | 'search'
  | 'circle'
  | 'times'
  | 'check'
  className?: string,
  size?:
  | 'xs'
  | 'sm'
  | 'lg'
  | '2x'
  | '3x'
  | '4x'
  | '5x'
  | '6x'
  | '7x'
  | '8x'
  | '9x'
  | '10x'
}

const faData = {
  updated_at: faHistory,
  created_at: faClock,
  programing: faCode,
  'personal-development': faRocket,
  etc: faGamepad,
  contact: faEnvelope,
  home: faHome,
  star: faStar,
  tag: faTags,
  list: faList,
  search: faSearch,
  circle: faCircleNotch,
  times: faTimes,
  check: faCheck
}

const Icon: React.FC<Props> = ({ icon, size, className }) => {
  const rootClassName = ClassNames({
    [styles.root]: true,
    [className!]: className
  })

  return (
    <>
      {
        faData[icon] ?
          <div className={rootClassName}>
            <FontAwesomeIcon icon={faData[icon]} size={size} />
          </div>
          :
          <span />
      }
    </>
  )
}

export default Icon
