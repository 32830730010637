import React from 'react'
import { Link } from 'gatsby'
import { IconLink } from '@/components/molecules'
import ClassNames from 'classnames'

import * as styles from './Breadcrumb.module.scss'

type Item = {
  name: string
  link: string
  icon?: string
}

type Props = {
  className?: string
  items: Item[]
}

const Breadcrumb: React.FC<Props> = ({ className, items }) => {
  const rootClassName = ClassNames({
    [styles.root]: true,
    [className!]: className
  })

  return (
    <div className={rootClassName}>
      {items.map((item, index) => (
        <span key={`${item.name}-span`}>
          {index !== 0 && <span className={styles.item} key={`${item.name}-slash`}>/</span>}
          {index === 0 && (
            <IconLink className={styles.icon_link}
              iconClassName={styles.icon}
              key={`${item.name}-icon-link`}
              item={item}
            />
          )}
          {index !== 0 && (
            <Link className={styles.subcategory} key={`${item.name}-link`} to={item.link}>
              {item.name}
            </Link>
          )}
        </span>
      ))}
    </div>
  )
}

export default Breadcrumb
