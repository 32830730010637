import React from 'react'
import { Link } from 'gatsby'
import ClassNames from 'classnames'

import { Icon } from '@/components/atoms'
import { MarkdownRemarkGroupConnection } from '@types/graphql-types'
import { pathOptimize, machinize, humanize } from '@/utils'

import * as styles from './TagList.module.scss'

type Props = {
  className?: string
  group: MarkdownRemarkGroupConnection[]
}

const TagList: React.FC<Props> = ({ className, group }) => {
  const rootClassName = ClassNames({
    [styles.root]: true,
    [className!]: className
  })

  return (
    <div className={rootClassName}>
      <span className={styles.title_root}>
        <Icon icon={"tag"} size={"sm"} className={styles.title_icon} />
        <span className={styles.title_title}>タグ</span>
      </span>
      <div className={styles.content_root}>
        <ul className={styles.content_ul}>
          {group.map(({ fieldValue, totalCount }) => {
            const mtag = machinize(fieldValue)
            const htag = humanize(fieldValue)
            return (
              <li className={styles.content_li} key={mtag}>
                <Link className={styles.link} to={pathOptimize(`/tags/${mtag}`)}>
                  #{htag} ({totalCount})
                </Link>
              </li>
            )
          })}
        </ul>
      </div>
    </div>
  )
}

export default TagList
