import React from 'react'
import Helmet from 'react-helmet'

import { SEOProps } from '../interface'
import AppConfig from '@/config/app'
import {
  BlogConfigProps,
  PostProps,
  CategoryProps,
  SubCategoryProps,
  TagProps,
  ItemProps
} from '../JSONLD/interface'

interface BaseSEOProps extends Required<Pick<SEOProps, 'type' | 'thumbnailPath'>> {
  item?: PostProps | CategoryProps | SubCategoryProps | TagProps | ItemProps
  jsonLD: string
}

const BaseSEO: React.FC<BaseSEOProps> = ({ jsonLD, type, item = {}, thumbnailPath }) => {
  const config = AppConfig()
  const { blog }: BlogConfigProps = config
  const thumbnail = blog.baseUrl + thumbnailPath
  const twitterCard = 'summary_large_image'

  return (
    <Helmet>
      <html lang="ja" />
      <meta name="google-site-verification" content="XD7C_d1Vk6Ox_nJ7MkK9G5kNPiMPOI6Z9qs7TNr_Y60" />
      <meta name="description" content={item.description || blog.description} />
      <meta name="image" content={thumbnail} />

      <script type="application/ld+json">{jsonLD}</script>

      <meta name="og:title" content={item.title || blog.title} />
      <meta name="og:description" content={item.description || blog.description} />
      <meta name="og:url" content={item.url || blog.baseUrl} />
      <meta name="og:type" content={type} />
      <meta name="og:site_name" content={item.title || blog.title} />
      <meta name="og:image" content={thumbnail} />
      <meta name="og:image:height" content="630" />
      <meta name="og:image:width" content="1200" />

      <meta name="twitter:card" content={twitterCard} />
      <meta name="twitter:image" content={thumbnail} />
      <meta name="twitter:title" content={item.title || blog.title} />
      <meta name="twitter:description" content={item.description || blog.description} />

      <link rel="canonical" href={item.url || blog.baseUrl} />
    </Helmet>
  )
}

export default BaseSEO
