export default () => {
  return {
    profile: {
      name: 'yukihirop',
      description: 'フロントエンド兼バックエンドなWEBエンジニアです。\n既存の考え方に囚われずに自由にツールを作るのが好きです。',
      imageUrl: 'https://www.gravatar.com/avatar/0455ed302b0012723b5e1fc7364d1254?r=g&d=mp'
    },
    blog: {
      title: 'Creator of @what',
      description: 'いつかBioに「Creator of ...」と書けるようなプログラマを目指している人のブログ',
      baseUrl: process.env.GATSBY_BASE_URL,
      imageUrl: ''
    }
  }
}
