import React, { useState, useCallback, FormEvent, useEffect } from 'react'
import { navigate } from 'gatsby'
import ClassNames from 'classnames'
import { ReCaptcha, loadReCaptcha } from 'react-recaptcha-v3'

import FormGroupInput from './FormGroupInput'
import FormGroupStatus, { Status } from './FormGroupStatus'
import Client from '@/utils/axios'

import * as styles from './ContactForm.module.scss'
import * as styles_input from './FormGroupInput/FormGroupInput.module.scss'

const encode = data => {
  return Object.keys(data)
    .map(key => encodeURIComponent(key) + "=" + encodeURIComponent(data[key]))
    .join("&");
}

type Props = {
  className?: string
}

const ContactForm: React.FC<Props> = ({ className }) => {
  const [name, setName] = useState('')
  const [email, setEmail] = useState('')
  const [subject, setSubject] = useState('')
  const [message, setMessage] = useState('')
  const [status, setStatus] = useState('' as Status)
  const [readOnly, setReadOnly] = useState(false)
  const [token, setToken] = useState('')

  const reCaptchaSiteKey = process.env.GATSBY_GOOGLE_RECAPTCHA_V3_SITE_KEY as string

  // https://www.netlify.com/blog/2017/07/20/how-to-integrate-netlifys-form-handling-in-a-react-app/
  const handleSubmit = useCallback(async (e: FormEvent<HTMLFormElement>): Promise<void> => {

    e.preventDefault()
    e.stopPropagation()

    setStatus('sending')

    try {
      // Try reCAPTCHA
      const gRecaptchaResponse = token
      const reCaptchaResult = await Client.post("/.netlify/functions/google-recaptcha-v3", { gRecaptchaResponse })

      if (reCaptchaResult.status !== 200) {
        // [NG] Try reCAPTCHA
        throw new Error(reCaptchaResult.data.toString())
      } else {
        // [OK] reCAPTCHA
        // Send Email Netlify
        const sendNetlify = await fetch("/", {
          method: "POST",
          headers: { "Content-Type": "application/x-www-form-urlencoded" },
          body: encode({ "form-name": "contact", name, email, subject, message })
        })

        // [OK] Send Email Netlify
        if (sendNetlify.ok) {
          // Send automatic reply email
          const autoSend = await Client.post("/.netlify/functions/send-contact-email", { name, email, subject, message })

          if (autoSend.status === 200) {
            navigate("/contact/success")
          } else {
            throw new Error(autoSend.data.data)
          }
        } else {
          throw new Error(sendNetlify!.body!.toString())
        }
      }
    } catch (err) {
      setStatus('error')
      throw err
    }
  }, [name, email, subject, message, token])

  useEffect(
    () => {
      setReadOnly(status === 'sending')
    },
    [status]
  )

  useEffect(
    () => {
      loadReCaptcha(reCaptchaSiteKey);
    },
    [reCaptchaSiteKey]
  )

  const handleChange = e => {
    const ename = e.target.name
    const evalue = e.target.value

    switch (ename) {
      case 'name':
        setName(evalue)
        break
      case 'email':
        setEmail(evalue)
        break
      case 'subject':
        setSubject(evalue)
        break
      case 'message':
        setMessage(evalue)
        break
    }
  }

  const requiredLabelClassName = ClassNames({
    [styles_input.label]: true,
    [styles_input.required]: true
  })

  const messageClassName = ClassNames({
    [styles_input.control]: true,
    [styles.form_message]: true
  })

  const rootClassName = ClassNames({
    [styles.root]: true,
    [className!]: className
  })

  return (
    <div className={rootClassName}>
      <div className={styles.title_root}>
        <h1 className={styles.title}>お問い合わせ</h1>
      </div>
      <div>
        <form
          name="contact"
          method="POST"
          data-netlify="true"
          data-netlify-honeypot="bot-field"
          onSubmit={handleSubmit}
        >
          <FormGroupInput type="text" title="お名前" name="name" value={name} onChange={handleChange} readOnly={readOnly} required />
          <FormGroupInput type="email" title="メールアドレス" name="email" value={email} onChange={handleChange} readOnly={readOnly} required />
          <FormGroupInput type="text" title="件名" name="subject" value={subject} onChange={handleChange} readOnly={readOnly} required />
          <div className={styles.form_group}>
            <label className={requiredLabelClassName}>メッセージ</label>
            <textarea
              className={messageClassName}
              name="message"
              rows={5}
              value={message}
              onChange={handleChange}
              readOnly={readOnly}
              required />
          </div>
          <ReCaptcha action="contact" sitekey={reCaptchaSiteKey} verifyCallback={setToken} />
          <FormGroupStatus status={status} />
          <div className={styles.form_group}>
            <p>
              <a href={"/privacy-policy"} target="_blank">プラバシーポリシー</a>をご確認いただき、記載されている内容に同意の上、お問い合わせください。
            </p>
            <div className={styles.form_submit}>
              <button
                className={styles.form_submit_btn}
                type="submit"
                disabled={!token}
              >
                送信
              </button>
            </div>
          </div>
        </form>
      </div>
    </div>
  )
}

export default ContactForm
